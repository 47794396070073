const API_URL = process.env.REACT_APP_API_URL;

export const fetchCategories = async () => {
    try {
        const response = await fetch(`${API_URL}categories`);
        if (!response.ok) {
            throw new Error('Falha ao carregar as categorias');
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Falha ao carregar as categorias: ${error.message}`);
    }
};

export const fetchPlace = async (slug, token = '') => {
    try {
        const headers = {};

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch(`${API_URL}places/preview/${slug}`, {
            headers
        });

        if (!response.ok) {
            throw new Error('Falha ao carregar os passeios');
        }

        return await response.json();
    } catch (error) {
        throw new Error(`Falha ao carregar os passeios: ${error.message}`);
    }
};

export const fetchTours = async () => {
    try {
        const response = await fetch(`${API_URL}places/home`);
        if (!response.ok) {
            throw new Error('Falha ao carregar os passeios');
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Falha ao carregar os passeios: ${error.message}`);
    }
};

export const fetchPlacesForMap = async () => {
    try {
        const response = await fetch(`${API_URL}places/home/map`);
        if (!response.ok) {
            throw new Error('Falha ao carregar os passeios');
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Falha ao carregar os passeios: ${error.message}`);
    }
};

export const oauthMe = async (uid, token) => {
    try {
        const response = await fetch(`${API_URL}oauth/me/${uid}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const loginUser = async (credentials) => {
    try {
        const response = await fetch(`${API_URL}login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const registerUser = async (credentials) => {
    try {
        const response = await fetch(`${API_URL}register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const contactForm = async (data) => {
    try {
        const response = await fetch(`${API_URL}contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const recoveryUser = async (credentials) => {
    try {
        const response = await fetch(`${API_URL}recovery`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const validateCode = async (credentials) => {
    try {
        const response = await fetch(`${API_URL}recovery/code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (credentials) => {
    try {
        const response = await fetch(`${API_URL}recovery/reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const profile = async (formData, token) => {
    try {
        const response = await fetch(`${API_URL}account/profile`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const documents = async (formData, token) => {
    try {
        const response = await fetch(`${API_URL}account/documents`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};


export const fetchAccountStatus = async (token) => {
    try {
        const response = await fetch(`${API_URL}account/status`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const savePlace = async (formData, token, slug = '') => {
    if (slug.length > 0) {
        slug = `/${slug}`;
    }

    try {
        const response = await fetch(`${API_URL}account/places/save${slug}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const fetchSchedules = async (token, tab) => {
    try {
        const response = await fetch(`${API_URL}account/schedules/${tab}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const cancelSubscription = async (eid, token) => {
    try {
        const response = await fetch(`${API_URL}account/subscriptions/remove/${eid}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};
export const removeTour = async (slug, token) => {
    try {
        const response = await fetch(`${API_URL}account/places/remove/${slug}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};
export const getTour = async (slug, token) => {
    try {
        const response = await fetch(`${API_URL}account/places/place/${slug}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};
export const getSubcategories = async (categoryId) => {
    try {
        const response = await fetch(`${API_URL}subcategories/${categoryId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};
export const getSchedules = async (data, slug) => {
    try {
        const response = await fetch(`${API_URL}places/check-schedules/${slug}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};


export const checkPaymentStatus = async (eid, token) => {
    try {
        const response = await fetch(`${API_URL}account/schedule/${eid}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};

export const bookNow = async (formData, token, slug = '') => {
    if (slug.length > 0) {
        slug = `/${slug}`;
    }

    try {
        const response = await fetch(`${API_URL}account/schedule${slug}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw JSON.stringify(errorData);
        }
    } catch (error) {
        throw error;
    }
};